import { useEffect } from "react";
import hero from "../assets/images/hero-phone.png";
import logo from "../assets/images/dilivva-icon.jpg";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import androidimg from "../assets/images/google-play-app-store.png"
import iosimg from "../assets/images/app-store.png"

const Partners = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section id="partners">
        <div className="container">
          <div className="row">
            <div className="col-md-7 send">
              <p className="senders">Partners</p>
              <h3>
                Pick up packages going your way and make
                <span className="time">extra cash</span>
              </h3>

              <p className="content">
                Whether it’s once in a while, every day or anything in between,
                delivering on Dilivva is the most flexible way to earn money on
                the side. Whether you like driving local or long haul, the
                Dilivva app makes it easy to earn cash and rewards.
              </p>
              <div className="apps mb-5">
                <a href="/partner">
                  <img
                    src={androidimg}
                    className="img-fluid m-2 app-img"
                    width="200"
                    alt="google-play"
                  />
                </a>
                <a href="/partner">
                  <img
                    src={iosimg}
                    className="img-fluid m-2 app-img"
                    width="192"
                    alt="app-store"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <img src={hero} className="img-fluid rounded" alt="partner" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-4">
              <h3>Why become a Dilivva Delivery <span className="time">Partner</span></h3>
              <p>
                As long as you can move, you should be able to earn something
                reasonable. Whether you’re running errands, doing a regional
                commute or road tripping across the country, Dilivva is a great
                way to earn cash driving from point A to B.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card border-0 mb-4">
                <div className="card-body">
                  <h5>Make more money daily</h5>
                  <p>
                    Earn an average of <strong>N8,500 per day</strong> on
                    multiple local Gigs and even more on long haul deliveries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 mb-4">
                <div className="card-body">
                  <h5>Drive packages, not people</h5>
                  <p>
                    No need to entertain anybody or keep people engaged. Sit
                    back and relax during your drive or travels
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 mb-4">
                <div className="card-body">
                  <h5>Choose your deliveries</h5>
                  <p>Choose when, where and how you want to make money.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card border-0 mb-4">
                <div className="card-body">
                  <h5>Know before you go</h5>
                  <p>
                    See guaranteed payout amount, pictures of items, plus pickup
                    and delivery notes upfront.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card border-0 mb-4">
                <div className="card-body">
                  <h5>No minimum vehicle standards</h5>
                  <p>
                    Get paid to drive your way, no matter what you drive. You
                    don’t even need your own car, you can use public
                    transportation… As long as you are moving, you should be
                    making money{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 my-5" style={{ backgroundColor: "#70eccb46" }}>
        <div className="container">
          <div className="row text-center">
            <div className="col mb-3">
              <div className="mb-4">
                <span className="transport">
                  <i className="fa-solid fa-bicycle"></i>
                </span>
              </div>
              <h4 className="mt-5 mb-4">Bicycle</h4>
            </div>
            <div className="col mb-3">
              <div className="mb-4">
                <span className="transport">
                  <i className="fa-solid fa-motorcycle"></i>
                </span>
              </div>
              <h4 className="mt-5 mb-4">Motorcycle</h4>
            </div>
            <div className="col mb-3">
              <div className="mb-4">
                <span className="transport">
                  <i className="fa-solid fa-car"></i>
                </span>
              </div>
              <h4 className="mt-5 mb-4">Car</h4>
            </div>
            <div className="col mb-3">
              <div className="mb-4">
                <span className="transport">
                  <i className="fa-solid fa-truck"></i>
                </span>
              </div>
              <h4 className="mt-5 mb-4">Truck</h4>
            </div>
            <div className="col mb-3">
              <div className="mb-4">
                <span className="transport">
                  <i className="fa-solid fa-bus"></i>
                </span>
              </div>
              <h4 className="mt-5 mb-4">Public bus</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div
              className="col-md-10 py-5 rounded"
              style={{ backgroundColor: "#0c1d37" }}
            >
              <h3 className="text-white text-center">
                Why Dilivva Delivery Partners Love Dilivva
              </h3>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner px-5">
                  <div className="carousel-item active">
                    <p className="content text-white p-5">
                      I just joined Dilivva early yesterday morning and by the
                      end of the day I had made 7 drops. And I totally had fun
                      doing it!
                    </p>
                    <small className="text-white float-end">
                      Victoria P. Ikeja Lagos
                    </small>
                  </div>
                  <div className="carousel-item">
                    <p className="content text-white p-5">
                      I love making money with Dilivva. The app is very easy to
                      use and flexible so I can work anytime of the day. Thanks
                      Dilivva!
                    </p>
                    <small className="text-white float-end">Jacob K. </small>
                  </div>
                  <div className="carousel-item">
                    <p className="content text-white p-5">
                      Coming home in between seasons, the flexibility and
                      freedom that Dilivva gives me to make money is great for
                      my family. It gives me the time I need to spend with them.
                    </p>
                    <small className="text-white float-end">Olusola O</small>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FloatingWhatsApp
        phoneNumber="+2349158867851"
        accountName="Dilivva"
        avatar={logo}
      />
    </>
  );
};

export default Partners;
