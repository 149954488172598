import React, { useEffect } from "react";

const ProhibitedItems = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <h1 className="my-5">List of Prohibited Items</h1>
          <h4>
            Effective:<span>September 9, 2022</span>
          </h4>
          <p>
            The following are a list of prohibited items a sender must not send
            through the Dilivva app.
          </p>
          <ul>
            <li>Alcoholic beverages</li>
            <li>Explosives</li>
            <li>Animal skins</li>
            <li>
              Articles of exceptional value (e.g., works of art, antiques,
              precious stones, gold and silver)
            </li>
            <li>Dangerous goods/Hazardous materials</li>
            <li>Firearms</li>
            <li>Furs</li>
            <li>Ivory and ivory products</li>
            <li>Live animals</li>
            <li>Money and negotiable items</li>
            <li>Plants</li>
            <li>Pornographic materials</li>
            <li>Tobacco and tobacco products</li>
            <li>Watches and Jewelries exceeding the value of 200 USD</li>
            <li>Bio-Harzadeous Materials</li>
            <li>Credit Cards</li>
            <li>Hard Drugs and entertainment/ recreational drugs</li>
            <li>Flammables</li>
            <li>Gases</li>
            <li>Jewelry containing precious stones or lead salt</li>
            <li>Poisons</li>
            <li>Radioactive Materials</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProhibitedItems;
