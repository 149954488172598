import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import career from "../assets/images/career.png";
import team from "../assets/images/team.png";

const Careers = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  return (
    <>
      <section className="py-5" id="hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6 py-5">
              <h2 className="mt-5">
                Are you looking for an <br />
                <span className="time">exciting career?</span>
              </h2>
              <p className="">
                Dilivva Africa Digital Tech Limited is looking for talented and
                driven individuals to join our team!
              </p>
            </div>
            <div className="col-md-6">
              <img src={career} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-5"
        id="download"
        style={{ backgroundColor: "#fbf2eb" }}
      >
        <div className="container">
          <div className="row reverse py-5">
            <div className="col-md-6">
              <img src={team} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <h3>
                Join the <span className="time">Dilivva</span> Team
              </h3>
              <p>
                As a leading provider of package delivery services, we pride
                ourselves on our commitment to innovation, efficiency, and
                customer satisfaction. At Dilivva, we believe that our employees
                are our greatest asset, and we're always looking for motivated
                individuals who share our passion for excellence.
              </p>

              <p>
                Whether you're an experienced professional or just starting out
                in your career, we offer a variety of opportunities for growth
                and development. From operations to technology, marketing to
                finance, there's something for everyone at Dilivva.
              </p>

              <p>
                So why wait? Visit our careers page at{" "}
                <a href="./career">www.dilivva.com/career</a> to learn more
                about our current openings and to submit your application today.
                Join us at Dilivva and be a part of our exciting journey as we
                revolutionize the world of package delivery!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-white" id="hero">
        <div className="container mt-5">
          <div className="d-flex justify-content-between mb-5">
            <h4>Available Positions</h4>
          </div>
          <div className="row mt-2 d-flex justify-content-center">
            <div className="col-md-10 my-3 P-3">
              <div className="card p-2">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="col-md-8">
                      <h6 className="fw-bold">
                        <span className="bg-warning p-2 rounded mx-3">
                          <i className="fa-solid fa-bag-shopping"></i>
                        </span>
                        Sales Lead
                      </h6>
                      <div className="row m-4">
                        <p>As a sales lead you would be responsible for coordinating the sales department, supporting management, and ensuring sales quotas are reached</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex justify-content-end">
                      <Link
                        to="/careers/details"
                        className="btn text-white"
                        style={{ background: "#d97706" }}
                      >
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* MODAL CONTENT */}
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Submit the following details to apply
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="my-4 form-group">
                  <label htmlFor="pickup">Full Name</label>
                  <input
                    className="form-control shadow-none"
                    placeholder="Enter full name"
                  ></input>
                </div>
                <div className="my-4 form-group">
                  <label htmlFor="destination">Email</label>
                  <input
                    className="form-control shadow-none"
                    placeholder="Enter email"
                  ></input>
                </div>
                <div className="my-4">
                  <label htmlFor="destination">Cover Letter</label>
                  <textarea
                    className="form-control shadow-none"
                    rows="5"
                    cols="50"
                    placeholder="Enter your text here..."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="">Resumé</label>
                  <input
                    className="form-control shadow-none"
                    type="file"
                  ></input>
                </div>
                <button type="submit" disabled={false}>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-white"
                        role="status"
                        style={{
                          width: "25px",
                          height: "25px",
                          fontSize: "10px",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
              <div className="text-center mt-4">
                {loading && (
                  <p className="text-success">Submited Successfully</p>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END MODAL CONTENT */}
    </>
  );
};

export default Careers;
