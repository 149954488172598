import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./assets/images/logo.png";

const Header = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const nav = document.querySelector("#header");
      if (window.scrollY > 0) {
        nav.classList.add("add-shadow");
      } else {
        nav.classList.remove("add-shadow");
      }
    });
  }, []);
  return (
    <>
      <section id="header">
        <Link to="/">
          <img src={logo} width="70" alt="" />
        </Link>
        <div>
          <ul id="navbar" className={`mt-2 ${active && "active"}`}>
            <li onClick={() => setActive(false)}>
              <Link to="./#sender">Sender</Link>
            </li>
            <li onClick={() => setActive(false)}>
              <Link to="/partner">Partner</Link>
            </li>
            <li onClick={() => setActive(false)}>
              <Link to="/store">Store</Link>
            </li>
            <li onClick={() => setActive(false)}>
              <Link to="/courier">Courier</Link>
            </li>
            <li onClick={() => setActive(false)}>
              <a href="https://blog.dilivva.com/blog">Blog</a>
            </li>
            <li onClick={() => setActive(false)}>
              <button>
                <a className="text-white" href="https://auth.dilivva.com/">
                  Get Started
                </a>
              </button>
            </li>
            <Link to="/" id="close">
              <i className="fa-solid fa-xmark" onClick={() => setActive(false)}></i>
            </Link>
          </ul>
        </div>
        <div id="mobile">
          <i id="bar" className="fas fa-bars" onClick={() => setActive(true)}></i>
        </div>
      </section>
    </>
  );
};

export default Header;
