import { useEffect } from "react";
import courier from "../assets/images/courier.svg";
import hero from "../assets/images/dilivva.png";
import DownloadApp from "../DownloadApp";

const Courier = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="py-5">
      <div className="container">
        <div className="row py-5">
          <div className="col-md-7">
            <h3>
              Courier <span className="time"> Company</span>
            </h3>
            <p className="content">
              Dilivva offers a platform for courier companies to earn more
              income. With dilivva's courier company service, you can easily
              view available packages in various areas, and you can have a
              dispatch sent to the pick up location and deliver the package,
              then get paid. Easy money
            </p>
          </div>
          <div className="col-md-5">
            <img src={courier} className="img-fluid p-5" alt="" />
          </div>
        </div>
        <div className="row reverse py-5">
          <div className="col-md-5">
            <img src={hero} className="img-fluid p-5" alt="" />
          </div>
          <div className="col-md-7">
            <h3>
              How it <span className="time"> works</span>
            </h3>
            <p className="content">
              When you've completed your registration for dilivva as a courier
              company, you will be dericted to register your various
              dispatchers with whatever vehicles they use. When this is all
              done, you can then assign available packages to your dispatchers.
              Once the delivery is completed, you get paid. It's that simple
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-7">
            <a
              href="https://courier.dilivva.com/#/sign-up"
              target="_blank"
              rel="noreferrer"
              className="btn-link shadow-none"
            >
              Register now <i className="fa-solid fa-arrow-right-long mx-2"></i>
            </a>
          </div>
        </div>
      </div>
      <DownloadApp />
    </section>
  );
};

export default Courier;
