import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PartnerAgreement from "./components/pages/PartnerAgreement";
import Faqs from "./components/pages/Faqs";
import Home from "./components/pages/Home";
import Partners from "./components/pages/Partners";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import ProhibitedItems from "./components/pages/ProhibitedItems";
import Terms from "./components/pages/Terms";
import Download from "./components/pages/Download";
import Careers from "./components/pages/Careers";
import CareerDetails from "./components/pages/CareerDetails";
import Courier from "./components/pages/Courier";
import Store from "./components/pages/Store";
import Track from "./components/pages/Track";

function App() {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === "/privacy-policy" || pathname === "/terms" ? "" : <Header /> }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partner" element={<Partners />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/partner-agreement" element={<PartnerAgreement />} />
        <Route path="/prohibited-items" element={<ProhibitedItems />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/download" element={<Download />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/details" element={<CareerDetails />} />
        <Route path="/store" element={<Store />} />
        <Route path="/courier" element={<Courier />} />
        <Route path="/track" element={<Track />} />
      </Routes>
      {pathname === "/privacy-policy" || pathname === "/terms" ? "" : <Footer /> }
    </>
  );
}

export default App;
