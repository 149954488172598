import React from "react";
import { Link } from "react-router-dom";
import logo from "../components/assets/images/logo.png";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4 px-4">
              <img src={logo} width="100" alt="" />
              <p>
                If your package needs to get there TODAY; use Dilivva. Whether
                it is across town or across state lines, we will get it there
                for you today!
              </p>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-3">
                  <p className="footer-header">Company</p>
                  <div className="footer-link">
                    <Link to="/partner">Partner</Link>
                    <a
                      href="https://store.dilivva.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Store
                    </a>
                    <Link to="/courier">Courier</Link>
                    <Link to="/careers">Careers</Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <p className="footer-header">Quick Links</p>
                  <div className="footer-link">
                    <Link to="/track">Track</Link>
                    <a href="/#estimate">Get Estimate</a>
                    <Link to="/faqs">FAQs</Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <p className="footer-header">Terms and Privacy</p>
                  <div className="footer-link">
                    <Link to="/terms">Terms and Conditions</Link>
                    <Link to="/partner-agreement">Partner Agreement</Link>
                    <Link to="/prohibited-items">Prohibited Items</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <p className="footer-header">Social</p>
                  <div className="footer-link">
                    <a
                      href="https://twitter.com/DilivvaApp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100088592211803"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    <a
                      href="https://www.instagram.com/dilivvaapp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://wa.me/message/SD4NC3UF5UV4I1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WhatsApp
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
