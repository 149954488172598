import React, { useEffect } from "react";

const PartnerAgreement = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <>
      <div className="container">
        <div className="row">
          <h1 className="my-5">Dilivva Partners Agreement</h1>
          <p>
            <i>Effective: February 15, 2021 (version 7)</i>
          </p>
          <p>
            This Dilivva Partners Agreement is a part of Dilivva’s Terms and
            Conditions (“Terms”) and is incorporated therein by this reference.
            Any capitalized terms used herein and not otherwise defined shall
            have the same definition as set forth in the Terms.
          </p>
          <p>
            In consideration of Partner being allowed to access the Dilivva
            Platform and the opportunity to offer on Gigs, as well as the mutual
            promises described herein, Dilivva and Partner (collectively “the
            parties”) agree as follows:
          </p>
          <p>
            <ol>
              <b>I. GENERAL</b> This Agreement and the Terms found at 
              www.dilivva.com/terms , as may be updated from time to time shall
              govern the relationship between Dilivva and Partner, as well as
              Partner’s performance of Delivery Services via the Dilivva
              Platform.
              <p>
                <b>II. DELIVERY SERVICES</b>
              </p>
              <li>
                Partner acknowledges that Partner is engaged in Partner’s own
                business, separate and apart from Dilivva’s business, which is
                to provide logistics management services and an online
                marketplace connection using web-based technology that connects
                businesses and consumers needing delivery services with those in
                the business of providing delivery services.
              </li>
              <p>
                <li>
                  From time to time, Partner may be notified of an available Gig.
                  For each Gig accepted by Partner (“Delivery Services”), Partner
                  agrees to provide Delivery Services to Partner’s Sender(s)
                  safely and on time. Partner acknowledges that Dilivva has
                  discretion as to which, if any, Gigs to offer to Partner, just
                  as Partner has the discretion whether and to what extent to
                  accept any Delivery Services. Nothing in this Agreement shall
                  guarantee Partner any particular volume of business for any
                  particular time period.
                </li>
              </p>
              <p>
                <li>
                  Partner shall have no obligation to accept or perform any
                  particular Gig or to perform any particular volume of Delivery
                  Services during the term of this Agreement; provided, once
                  Partner accepts a Gig, Partner is contractually bound to
                  complete the Delivery Services in accordance with all Sender
                  specifications and the terms laid out in this Agreement.
                </li>
              </p>
              <p>
                <li>
                  Partner has the right to cancel, from time to time, a Delivery
                  Services when, in the exercise of Partner’s reasonable
                  discretion and business judgment, it is appropriate to do so.
                  Notwithstanding the foregoing, Partner understands that
                  cancellations may lead to low customer/Sender ratings, and
                  complaints from Senders, Delivery Order recipients and third
                  parties. A failure to maintain favorable customer/Sender
                  ratings and/or receiving complaints from Senders, Delivery
                  Order recipients and/or third parties will constitute a
                  material breach of this Agreement, giving Dilivva the right to
                  temporarily lock or permanently deactivate Partner’s Dilivva
                  account.
                </li>
              </p>
              <p>
                <li>
                  Partner understands that the Dilivva Platform automatically
                  provides updates to Senders and Delivery Order recipients as
                  to the status of Delivery Services, and gives the Dilivva
                  support team (“Dilivva Support”) the ability to communicate
                  with Partners, Senders and Delivery Order recipients in order
                  to facilitate the Delivery Services. Partner authorizes the
                  Dilivva Platform and Dilivva Support to communicate with
                  Partner and the Sender and/or Delivery Order recipient on
                  Partner’s behalf to solely to facilitate Partner’s performance
                  of a Delivery Services. Partner must have a mobile device with
                  the Dilivva. App running while performing all Delivery
                  Services. Partner must not disable the Dilivva App or its geo
                  location software at any time while performing Delivery
                  Services.
                </li>
              </p>
              <p>
                <li>
                  Partner understands and agrees that the parameters of each
                  Delivery Service is established by the Sender, not Dilivva,
                  and represents the end result desired by the Sender, not the
                  means by which Partner is to accomplish the result. Under no
                  circumstances shall Dilivva be authorized to control the
                  manner or means by which Partner performs Delivery Services or
                  any other services contemplated under this Agreement.
                  Specifically, but not exclusively:
                  <ul>
                    <li>
                      Dilivva does not require any specific type, or quality, of
                      Partner’s choice of transportation.
                    </li>
                    <li>
                      Partner does not have a supervisor or any individual at
                      Dilivva to whom they report.
                    </li>
                    <li>
                      Partner does not wear a uniform designating Dilivva as the
                      provider of Delivery Services.
                    </li>
                    <li>
                      Partner does not use any signage or designation of Dilivva
                      on his/her/its vehicle while providing
                    </li>
                    <li>
                      Delivery Services, unless required by the Sender,
                      recipient, pick-up or drop-off locations.
                    </li>
                    <li>
                      Dilivva does not have control over Partner’s personal
                      appearance.
                    </li>
                  </ul>
                </li>
              </p>
              <p>
                <li>
                  As an independent business enterprise, Partner retains the
                  right to perform services (whether delivery services or other
                  services) for others and to hold him/herself out to the
                  general public as a separately established business. The
                  parties recognize that they are or may be engaged in similar
                  arrangements with others and nothing in this Agreement shall
                  prevent Partner or Dilivva from doing business with others.
                  Dilivva does not have the right to restrict Partner from
                  performing services for other businesses, customers or
                  consumers at any time, even if such business directly competes
                  with Dilivva, and even during the time Partner is logged into
                  the Dilivva Platform. Partner’s right to compete with Dilivva,
                  or perform services for business that compete with Dilivva,
                  will survive even after termination of this Agreement.
                </li>
              </p>
              <p>
                <li>
                  Partner is not required to purchase, lease, or rent any
                  products, equipment or services from Dilivva as a condition of
                  doing business with Dilivva or entering into this Agreement.
                </li>
              </p>
              <p>
                <li>
                  In the event that the Partner fails to fully perform any Delivery
                  Services in accordance with this Agreement and the Terms (a
                  “Service Failure”) due to Partner’s action or omission, or the
                  act or omission of someone in Partner’s control, Partner shall
                  forfeit all or part of the agreed upon fee for that service.
                </li>
              </p>
              <p>
                <li>
                  Partner agrees to immediately notify Dilivva in writing by
                  submitting a support inquiry through Dilivva Support at 
                  support@Dilivva.com  if Partner’s services or scope of work
                  differ in any way from what is contemplated in this Section
                  II.
                </li>
              </p>
              <p>
                <li>
                  As a partner using the dilivva partner application, you acknowledge 
                  and agree that dilivva africa digital tech limited may collect and 
                  track your location information using gps or other location-based technologies 
                  while the application is running in the background or foreground. this location 
                  information will be used to ensure successful delivery of packages and to improve 
                  the quality of our services. we will use this information solely for the purposes described 
                  in our privacy policy, and we will not disclose your location information to third parties 
                  without your consent, except as required by law. by using the dilivva partner application, 
                  you consent to our collection and use of your location information as described in these terms 
                  and conditions and in our privacy policy.
                </li>
            </p>
            </ol>
            <ol>
              <p>
                <b>III. PARTNER PERSONNEL</b>
              </p>
              <li>
                Partner is not required to perform any Delivery Services
                personally, but may, to the extent permitted by law and subject
                to the terms of this Agreement and the Terms, hire or engage
                others as employees or subcontractors (collectively “Personnel”)
                to perform all or some of the Delivery Services, provided all
                Partner Personnel are registered and have an account on the
                Dilivva Platform and meet all requirements applicable to Partners
                contained in the Terms including, but not limited to,
                presenting, or agreeing to Dilivva obtaining, their consumer
                report and/or investigative consumer report and agreeing to the
                Partners Agreement, prior to Partner using such Partner Personnel
                to perform Delivery Services on Partner’s behalf. The parties
                acknowledge that the sole purpose of this requirement is to
                ensure Partner’s and its Personnel’s compliance with the terms of
                this Agreement and the Terms.
              </li>
              <p>
                <li>
                  If Partner intends to use Partner Personnel, Partner must inform
                  Dilivva in writing at  support@Dilivva.com  at least 7 days in
                  advance , and provide the name(s) and account(s) of such
                  Personnel performing Delivery Services. To the extent Partner
                  furnishes his/her/its own Personnel, Partner shall be solely
                  responsible for the direction and control of the Personnel it
                  uses to perform all Delivery Services, and all actions and
                  omissions of any Partner Personnel in performing Delivery
                  Services, as if Partner was performing such Delivery Services
                  himself/herself/itself.
                </li>
              </p>
              <p>
                <li>
                  If Partner intends to use Partner Personnel, Partner must inform
                  Dilivva in writing at  support@Dilivva.com  at least 7 days in
                  advance , and provide the name(s) and account(s) of such
                  Personnel performing Delivery Services. To the extent Partner
                  furnishes his/her/its own Personnel, Partner shall be solely
                  responsible for the direction and control of the Personnel it
                  uses to perform all Delivery Services, and all actions and
                  omissions of any Partner Personnel in performing Delivery
                  Services, as if Partner was performing such Delivery Services
                  himself/herself/itself.
                </li>
              </p>
              <p>
                <li>
                  Unless mandated by law, Dilivva shall have no authority to
                  withhold state or federal income taxes, social security taxes,
                  unemployment insurance taxes/contributions, or any other
                  local, state or federal tax on behalf of Partner or his/her/its
                  Personnel.
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>IV. PARTNER'S AGREEMENTS, REPRESENTATIONS AND WARRANTIES</b>
              </p>
              <li>
                Partner represents that he/she/it operates an independently
                established enterprise that provides delivery and other
                services, and that he/she/it satisfies all legal requirements
                and has all necessary licenses and permits necessary to perform
                any and all Delivery Services. As an independent enterprise,
                Partner shall be solely responsible for determining how to
                operate his/her/its business, whether he/she/it is qualified to
                perform Delivery Services, and how to perform Delivery Services.
              </li>
              <p>
                <li>
                  Partner agrees to fully perform Delivery Services in a timely,
                  efficient, safe, and lawful manner. Dilivva shall not have the
                  right to, and shall not, control the manner, method or means
                  Partner uses to perform the Delivery Services. Instead, Partner
                  shall be solely responsible for determining the most
                  effective, efficient, and safe manner to perform the Delivery
                  Services, including determining the manner of pickup,
                  delivery, and route selection.
                </li>
              </p>
              <p>
                <li>
                  If Partner intends to use Partner Personnel, Partner must inform
                  Dilivva in writing at  support@Dilivva.com  at least 7 days in
                  advance , and provide the name(s) and account(s) of such
                  Personnel performing Delivery Services. To the extent Partner
                  furnishes his/her/its own Personnel, Partner shall be solely
                  responsible for the direction and control of the Personnel it
                  uses to perform all Delivery Services, and all actions and
                  omissions of any Partner Personnel in performing Delivery
                  Services, as if Partner was performing such Delivery Services
                  himself/herself/itself.Partner understands that Senders expect
                  that Partner and its Personnel will perform the Delivery
                  Services in a professional manner, and in accordance with the
                  highest industry standards applicable to the providing of
                  Delivery Services. Therefore, Partner agrees, for itself and
                  any Partner Personnel it uses that during the performance of
                  Delivery Services, that Partner and any Partner Personnel shall:
                  <ul>
                    <li>
                      use vehicles in good operating condition and, on the
                      outside, clean and well maintained without material
                      physical damage, and on the inside smoke and odor free,
                      and free of excessive petdander. If required by the laws
                      of the state in which Partner or its Personnel perform
                      Delivery Services, and all such vehicles must have a
                      standard, general inspection and needed maintenance
                      performed on their vehicle no less than yearly. Partner
                      must keep records of all such inspections and shall
                      provide latest inspection records to Dilivva upon request.
                    </li>
                    <li>
                      not use open convertibles or open truck beds in the
                      performance of any Delivery Services containing live
                      animals, perishable or delicate items.
                    </li>
                    <li>
                      have a well-groomed appearance, and will not wear clothing
                      displaying the logo or brand of any company or entity
                      (unless the logo or brand being displayed is Partner’s own
                      business logo), or any other clothing, which is obviously
                      and generally offensive.
                    </li>
                    <li>
                      not wear a uniform designating Dilivva as the provider of
                      Delivery Services.
                    </li>
                    <li>
                      not use any signage or other designation of Dilivva on
                      his/her/its vehicle while providing Delivery Services;
                      provided this shall not apply if the pick-up location
                      and/or drop off location, or applicable law or regulation,
                      specifically require that Partner’s vehicle be identified
                      as being used on the Dilivva Platform. In such cases only,
                      Sender or Dilivva on Sender’s behalf, will assign such
                      identification solely in order to facilitate Partner’s
                      performance of such Gig; provided that such signage is not
                      used on the vehicle for any other Sender Gigs or at any
                      other times.
                    </li>
                    <li>
                      not smoke at any time during Delivery Services, including
                      on the way to pick up a delivery.
                    </li>
                    <li>
                      not participate in any other activities while performing
                      Delivery Services that could take the focus off their
                      assigned responsibilities, including being under the
                      influence of any illegal drugs and/or alcohol or not
                      abiding by all laws regarding talking or texting while
                      driving.
                    </li>
                    <li>
                      not enter any Delivery Order recipient’s home, nor enter
                      or access any secure, private or employee only areas in
                      any place of business while performing Delivery Services
                      under any circumstances.
                    </li>
                    <li>
                      not carry on their person any personal weapon or other
                      item designed or used for inflicting bodily harm or
                      physical damage (including knives and firearms) when
                      entering any pick-up or drop off location, including
                      places of business, a Sender or Delivery Order recipient’s
                      property, or while picking-up or delivering goods for
                      Senders under any circumstances. Notwithstanding Partner
                      and/or its Personnel may possess a personal weapon inside
                      their vehicle if they have a valid license and legal right
                      to do so in the State where Delivery Services are
                      provided, and then only if such weapon is locked securely
                      in the vehicle at all times they are away from their
                      vehicle.
                    </li>
                    <li>
                      not be accompanied by minors under any circumstances while
                      performing Delivery Services.
                    </li>
                    <li>
                      not be accompanied by any unnecessary Personnel or other
                      persons.
                    </li>
                    <li>
                      not be accompanied by any other person when delivering
                      Gigs containing prescription medicine or pharmaceuticals.
                    </li>
                  </ul>
                </li>
              </p>
              <p>
                <li>
                  Partner understands and agrees that the above standards have
                  been established by Senders, and not Dilivva, and indicate
                  what behaviors Senders deem to be professional and what type
                  of performance is of acceptable quality. Partner’s failure to
                  perform Delivery Services in accordance with this Section IV
                  may lead to low customer/Sender ratings and complaints from
                  Senders, Delivery Order recipients and other third parties. A
                  failure to maintain favorable customer/Sender ratings, and/or
                  receiving complaints from Senders, Delivery Order recipients,
                  or third parties will constitute a material breach of this
                  Agreement, giving Dilivva the right to terminate this
                  Agreement, and/or Dilivva the right to temporarily lock or
                  permanently deactivate Partner’s Dilivva account.
                </li>
              </p>
              <p>
                <li>
                  Partner acknowledges and agrees that during the performance of
                  Delivery Services, he/she/it and/or his/her/its Personnel will
                  gain knowledge of third parties’ confidential, proprietary,
                  protected health, and/or personally identifiable information
                  (“Confidential Information”). Partner expressly acknowledges
                  and agrees that Partner and all Partner Personnel are subject to
                  the duty on Dilivva’s part to maintain the confidentiality of
                  such Confidential Information, and shall hold all such
                  information in absolute confidence and not disclose it to any
                  other person, except as is strictly necessary in carrying out
                  Delivery Services hereunder. Partner acknowledges that
                  he/she/it has read and understands all provisions in the
                  Dilivva Terms addressing Confidential Information and agrees
                  that the unauthorized disclosure of any Confidential
                  Information will constitute a material breach of this
                  Agreement, giving Dilivva the right to temporarily lock or
                  permanently deactivate Partner’s Dilivva account and/or the
                  account of any Partner Personnel.
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>V. RELATIONSHIP OF PARTIES</b>
              </p>
              <li>
                The parties acknowledge and agree that this Agreement is between
                two co-equals, independent business enterprises that are
                separately owned and operated. The parties intend this Agreement
                to create the relationship of principal and independent Partner
                and not that of employer and employee. The parties are not
                employees, agents, joint venturers, or partners of each other
                for any purpose. Neither party shall have the right to bind the
                other by contract or otherwise.
              </li>
              <p>
                <li>
                  Dilivva shall not have the right to, and shall not, control
                  the manner or the method of accomplishing Delivery Services to
                  be performed by Partner. The parties acknowledge and agree that
                  any provisions in this Agreement setting forth performance
                  standards or having the effect of reserving ultimate authority
                  in Dilivva, have been inserted (i) at the request and on
                  behalf of Senders and are included specifically for their
                  benefit, (ii) for the safety of the other users of the Dilivva
                  Platform, including Senders, Delivery Order recipients and
                  other Partners, and/or (iii) to achieve compliance with
                  applicable federal, state, or local laws, regulations, and
                  interpretations thereof.
                </li>
              </p>
              <p>
                <li>
                  Dilivva shall report all payments made to Partner on a calendar
                  year basis using an appropriate IRS Form 1099, if the volume
                  of payments to Partner qualify. Partner agrees to report all
                  such payments and any cash gratuities to the appropriate
                  federal, state and local taxing authorities.
                </li>
              </p>
              <p>
                <li>
                  Partner understands and agrees that providing Delivery Services
                  via the Dilivva Platform is not intended to be, nor shall be
                  used by Partner or its Personnel as full-time employment, and
                  Partner agrees that (i) under NO circumstances may Partner
                  provide, or have provided by Personnel on his/her/its behalf,
                  Delivery Services or other services via the Dilivva Platform,
                  whether through the Dilivva App generally, or through any
                  other Dilivva or Dilivva affiliate program, for a combined
                  total in excess of 7 hours per day, and/or 35 hours per
                  calendar week, and (ii) that neither Partner nor its Personnel
                  shall seek non- voluntary compensation from Dilivva, a Sender,
                  or any other third-party, and Partner hereby waives any rights
                  to, any benefits, overtime wages, or the likes from Dilivva,
                  Sender and/or any other third party.
                </li>
              </p>
              <p>
                <li>
                  Partner shall be fully liable for, and shall first pay
                  directly, or second reimburse, Dilivva and/or Sender for any
                  additional fees, charges or other obligation (monetary or
                  otherwise) placed on Dilivva and or Sender, whether for
                  overtime wages, employee benefits, workmen’s compensation, or
                  otherwise, including without limitation any reasonable legal
                  fees, other fees, charges, penalties and/or other obligation
                  (monetary or otherwise) should Partner or any of Partner’s
                  Personnel breach this Section V.
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>VI. PAYMENT FOR SERVICES</b>
              </p>
              <li>
                Partner will receive payment per Delivery Services satisfactorily
                rendered. From time to time, Dilivva or its affiliates may offer
                other Delivery Services for Partner to earn more money for
                performing Delivery Services at specified times or in specified
                locations. Nothing prevents the parties from negotiating a
                different rate of pay for such services, and Partner is free to
                accept or deny any such opportunities to earn different rates of
                pay.
              </li>
              <p>
                <li>
                  Partner will receive payment per Delivery Services
                  satisfactorily rendered. From time to time, Dilivva or its
                  affiliates may offer other Delivery Services for Partner to
                  earn more money for performing Delivery Services at specified
                  times or in specified locations. Nothing prevents the parties
                  from negotiating a different rate of pay for such services,
                  and Partner is free to accept or deny any such opportunities to
                  earn different rates of pay.
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>VII. DISPUTES, DEACTIVATIONS AND APPEALS</b>
              </p>
              <li>
                In the event there is a Service Failure, Partner shall not be
                entitled to payment as described above (as determined in
                Dilivva’s reasonable discretion). Any withholding of payment
                shall be done after an investigation and based upon evidence as
                may be provided by the Sender, Delivery Order recipient, Partner,
                and any other party with information relevant to the dispute.
                Dilivva shall make the determination as to whether a Service
                Failure was the result of Partner’s action/omission. Partner shall
                have the right to challenge Dilivva’s determination through any
                legal means contemplated by this Agreement; however, Partner
                shall notify Dilivva in writing at support@Dilivva.com of
                Partner’s intent to challenge Dilivva’s determination, and
                provide Dilivva the opportunity and a reasonable time within
                which to resolve the dispute.
                <p>
                  Partner should include any documents or other information in
                  support of his/her/its challenge.
                </p>
              </li>
              <p>
                <li>
                  In the event Dilivva fails to remit payment in a timely or
                  accurate manner, Partner shall have the right to seek proper
                  payment by any legal means contemplated by this Agreement and,
                  should Partner prevail, shall be entitled to recover reasonable
                  costs incurred in pursuing proper payment, provided, however,
                  Partner shall first inform Dilivva in writing at 
                  support@Dilivva.com  of the failure and provide Dilivva a the
                  opportunity and a reasonable time within which to cure it.
                </li>
              </p>
              <p>
                <li>
                  In the event Dilivva makes the decision to permanently
                  deactivate Partner’s and/or Partner’s Personnel’s account, such
                  decision may be eligible for appeal.  Partner understands that
                  certain deactivation decisions, especially those related to
                  zero tolerance violations, are not eligible for appeal.
                  Otherwise the majority of deactivation decisions will be
                  eligible. Partner acknowledges and agrees that ultimate
                  determination of eligibility will be made by Dilivva on a
                  case-by-case basis.  In order to file an appeal, Partner must
                  follow the instructions provided by Dilivva in the email
                  notifying Partner of the deactivation of their account.  Partner
                  agrees that Partner will receive only one appeal and that all
                  appeal decisions are final.
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>VIII. EQUIPMENTS AND EXPENSES</b>
              </p>
              <li>
                Partner represents that he/she/it has or can lawfully acquire all
                equipment necessary for performing Delivery Services
                (“Equipment”), and Partner is solely responsible for ensuring
                that the vehicle used conforms to all vehicle laws pertaining to
                safety, equipment, inspection, and operational capability.
              </li>
              <p>
                <li>
                  Partner agrees that he/she/it is responsible for all costs and
                  expenses arising from Partner’s performance of Delivery
                  Services, including, but not limited to, costs related to
                  Partner’s Personnel and Equipment. Except as otherwise required
                  by law, Partner assumes all risk of damage or loss to its
                  Equipment.
                </li>
              </p>
              <p>
                <li>
                  Notwithstanding the above, Partner will be reimbursed by Sender
                  for parking fees and/or tolls incurred in performing a Gig
                  provided the Partner presents a receipt or other adequate
                  evidence of incurring the same within fifteen (15) days of
                  incurring the expense.
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>IX. INSURANCE AND PERMITS</b>
              </p>
              <li>
                Partner agrees, as a condition of doing business with Dilivva,
                that at all times Partner has an active account with Dilivva,
                Partner will maintain current insurance, in amounts and of types
                required by law to provide the Delivery Services, as well as all
                required licenses and permits, at his/her/its own expense.
                Partner acknowledges that failure to secure or maintain
                satisfactory insurance coverage or required licenses or permits
                shall be deemed a material breach of this Agreement, and may
                result in the temporary lock on, or permanent deactivation of,
                Partner’s account.
              </li>
              <p>
                <li>
                  Partner agrees to deliver to Dilivva, upon request, current
                  certificates of insurance as proof of coverage, as well as
                  copies of current permits and licenses. Partner agrees to
                  provide updated certificates each time Partner purchases,
                  renews, or alters Partner’s insurance coverage, and each time
                  Partner renews or terminates a required license or permit.
                  Partner agrees to give Dilivva at least thirty (30) days’ prior
                  written notice before cancellation of any insurance policy, or
                  termination of any permit or license, required by this
                  Agreement.
                </li>
              </p>
              <p>
                <li>
                  Partner agrees that Partner will maintain sufficient insurance
                  to cover any risks or claims arising out of or related to
                  Partner’s relationship with Dilivva, including workers’
                  compensation insurance for Partner Personnel where required by
                  law. Partner acknowledges and understands that neither Partner
                  nor any Partner Personnel will be eligible for workers’
                  compensation benefits through Dilivva or any Sender, and
                  Partner is instead responsible for maintaining Partner’s own
                  workers’ compensation insurance or occupational accident
                  insurance. Partner’s maintenance of Partner’s own workers’
                  compensation insurance or occupational accident insurance will
                  not disqualify Partner from participating in any Occupational
                  Accident Insurance Policy for Partners, which Dilivva may make
                  available in the future to Partners generally.
                </li>
              </p>
              <p>
                <li>
                  <b>
                    <i>
                      You expressly acknowledge and agree that Dilivva does not
                      provide automobile liability, workers’ compensation or
                      health insurance coverage to Partners and is not
                      responsible for paying for any liability that may arise
                      from Partner’s performance of Delivery Services, including
                      any bodily injury or damage to property caused by or to
                      Partner or any Partner Personnel while performing Delivery
                      Services.
                    </i>
                  </b>
                </li>
              </p>
            </ol>
            <ol>
              <p>
                <b>X. INDEMNITY AND LIMITATION LIABILITY</b>
              </p>
              <li>
                Dilivva agrees to indemnify, protect and hold harmless Partner
                from any and all third-party liabilities, losses, damages,
                fines, civil penalties, settlements, judgments, costs and
                expenses (including without limitation reasonable attorney‘s
                fees and costs) (“Losses”) incurred in connection with any
                judicial or non-judicial claim, action, demand, suit, or
                proceeding asserted by a third party (including governmental
                entities) (collectively, “Claims”) against Partner and arising
                solely and directly from Dilivva’s willful misconduct in
                providing Partner the opportunity to offer on Gigs.
              </li>
              <p>
                <li>
                  Partner agrees to indemnify, protect and hold harmless Dilivva,
                  and all parent, subsidiary and/or affiliated companies, as
                  well as its and their past and present successors, assigns,
                  officers, owners, directors, agents, representatives,
                  attorneys, and employees (“Dilivva Indemnitees”), from any and
                  all Losses incurred in connection with Claims asserted by a
                  third party against a Dilivva Indemnitee arising directly or
                  indirectly from, or as a result of or in connection with,
                  Partner’s or Partner’s Personnel’s: (i) breach of this Agreement
                  or the Terms, (ii) violation of any law or the rights of any
                  third party, including, without limitation, other users of the
                  Dilivva Platform, other Partners, Senders, motorists,
                  pedestrians and other third parties; (iii) interactions with a
                  third party breaching provisions of this Agreement or the
                  Terms, including using the personal information of a party in
                  any manner outside providing Delivery Services hereunder, (iv)
                  ownership, use or operation of a any vehicle used on a
                  Delivery Services, (v) failure to have proper insurance,
                  licenses, permits or authorizations to provide Delivery
                  Services, (vi) tax liabilities and responsibilities for
                  payment of all federal, state and local taxes, including, but
                  not limited to all payroll taxes, self-employment taxes,
                  workers compensation premiums, and any contributions imposed
                  or required under federal, state and local laws, with respect
                  to Partner and Partner’s Personnel, (vii) from all costs of
                  Partner’s business, including, but not limited to, any payment
                  of compensation to Partner Personnel, expense of, and
                  responsibility for, any and all applicable insurance, local,
                  state or federal licenses, permits, taxes, and assessments of
                  any and all regulatory agencies,boards , and (viii) possessing
                  a weapon during a Delivery Service, in each case except to the
                  extent such Claim was caused by the gross negligence or
                  willful misconduct of a Dilivva Indemnitee.
                </li>
              </p>
              <p>
                <li>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS
                  PartnerS AGREEMENT OR THE TERMS, IN NO EVENT SHALL DILIVVA BE
                  LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT
                  UNDER ANY THEORY OF CONTRACT, STRICT LIABILITY, TORT
                  (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR ANY OTHER LEGAL
                  OR EQUITABLE THEORY FOR ANY AMOUNT GREATER THAN THE AMOUNT
                  Partner WAS PAID FOR PERFORMING SERVICES ON THE DILIVVA
                  PLATFORM HEREUNDER IN THE SIX (6) MONTHS IMMEDIATELY PRIOR TO
                  THE DATE THE CLAIM AROSE; OR (II) ANY CLAIM FOR ANY INDIRECT,
                  WILLFUL, PUNITIVE, INCIDENTAL, EXEMPLARY, SPECIAL OR
                  CONSEQUENTIAL DAMAGES, FOR LOSS OF BUSINESS PROFITS, OR
                  DAMAGES FOR LOSS OF BUSINESS OF A PARTY OR ANY THIRD PARTY
                  ARISING OUT OF THIS AGREEMENT, OR INACCURACY OF DATA OF ANY
                  KIND, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL
                  THEORY, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY
                  OF SUCH DAMAGES.
                </li>
              </p>
              <p>
                <b>XI. LIGITATION CLASS ACTION WAIVER</b> To the extent allowed
                by applicable law, separate and apart from any Mutual
                Arbitration Provision agreed to by Partner, Partner agrees that
                any proceeding to litigate in court any dispute arising out of
                or relating to this Agreement, whether because Partner opted out
                of the Mutual Arbitration Provision or any other reason, will be
                conducted solely on an individual basis, and Partner agrees not
                to seek to have any controversy, claim or dispute heard as a
                class action, a representative action, a collective action, a
                private attorney-general action, or in any proceeding in which
                Partner acts or proposes to act in a representative capacity
                (“Litigation Class Action Waiver”). Partner further agrees that
                no proceeding will be joined, consolidated, or combined with
                another proceeding, without the prior written consent of all
                parties to any such proceeding. If a court of competent
                jurisdiction determines that all or part of this Litigation
                Class Action Waiver is unenforceable, unconscionable, void or
                voidable, the remainder of this Agreement shall remain in full
                force and effect..
              </p>
            </ol>
            <ol>
              <p>
                <b>XII. MISCELLANEOUS</b>
              </p>
              <li>
                This Agreement and the Terms shall constitute the entire
                agreement and understanding between the parties with respect to
                the subject matter of this Agreement. Partner shall have the
                right to discuss any changes with Dilivva, and shall solely
                determine whether to continue his/her contractual relationship
                with Dilivva before accepting any such modifications,
                alterations, changes or amendments.
              </li>
              <p>
                <li>
                  The failure of Dilivva or Partner in any instance to insist
                  upon a strict performance of the terms of this Agreement or to
                  exercise any option herein, shall not be construed as a waiver
                  or relinquishment of such term or option and such term or
                  option shall continue in full force and effect.
                </li>
              </p>
              <p>
                <li>
                  Captions appearing in this Agreement are for convenience only
                  and do not in any way limit, amplify, modify, or otherwise
                  affect the terms and provisions of this Agreement.
                </li>
              </p>
              <p>
                <li>
                  If any part of this Agreement or the Terms is declared
                  unlawful or unenforceable, the remainder of this Agreement and
                  the Terms shall remain in full force and effect.
                </li>
              </p>
              <p>
                <li>
                  Partner agrees to notify Dilivva in writing at 
                  support@Dilivva.com  of any breach or perceived breach by
                  Dilivva of this Agreement, of any claim arising out of or
                  related to this Agreement, or of any claim that Partner’s
                  services or scope of work differ in any way from what is
                  contemplated in this Agreement, including but not limited to
                  the terms in Sections II (Delivery Services), and Section IV
                  (Partner’s Agreements, Representations and Warranties), or if
                  the relationship of the parties differs from the terms
                  contemplated in Section V (Relationship of Parties)
                </li>
              </p>
            </ol>
          </p>
        </div>
      </div>
    </>
  );
};

export default PartnerAgreement;
