import googlePlay from "./assets/images/google-play-app-store.png";
import appStore from "./assets/images/app-store.png";

const DownloadApp = () => {
  return (
    <section
      className="py-5"
      id="download"
      style={{ backgroundColor: "#fbf2eb" }}
    >
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 text-center">
            <h3>
              Download on <span className="time">app store</span> and{" "}
              <span className="time">play store</span>
            </h3>
            <div className="apps">
              <a
                href="https://play.google.com/store/apps/details?id=com.nqb8.dilivvasender"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={googlePlay}
                  className="img-fluid mx-2 app-img"
                  width="170"
                  alt="google-play"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/dilivva-sender/id1662208767"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={appStore}
                  className="img-fluid mx-2 app-img"
                  width="170"
                  alt="app-store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
