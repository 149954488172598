import { useEffect } from "react";
import store from "../assets/images/store1.svg";
import store2 from "../assets/images/store2.svg";
import store3 from "../assets/images/store3.svg";
import DownloadApp from "../DownloadApp";

const Store = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row py-5">
            <div className="col-md-7">
              <h3>
                What is <span className="time"> Dilivva</span> store about?
              </h3>
              <p className="content">
                Dilivva store is an e-commerce platform that allows your
                customers to make payment for their packages and the delivery
                fee for the package simultaneously..
              </p>
              <a
                href="https://store.dilivva.com/"
                target="_blank"
                rel="noreferrer"
                className="btn-link"
              >
                Go To Store
              </a>
            </div>
            <div className="col-md-5">
              <img src={store} className="img-fluid p-5" alt="" />
            </div>
          </div>
          <div className="row reverse py-5">
            <div className="col-md-5">
              <img src={store2} className="img-fluid p-5" alt="" />
            </div>
            <div className="col-md-7">
              <h3>
                Why choose <span className="time"> Dilivva</span> store?
              </h3>
              <p className="content">
                Dilivva store saves you a lot of stress and time. Using the
                Dilivva cost estimation system, you can determine exactly how
                much it will cost to send your customers goods to them and they
                can make the payment for their delivery simultaneously with
                their payments for the goods.
              </p>
              <p className="content">
                The system is really cost effective, easy to manage and the
                payment process is fast and seamless. We also pride ourselves in
                ensuring that your customers get their goods as soon as it is
                ready.
              </p>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-7">
              <h3>
                How To Register for <span className="time"> Dilivva</span> store
              </h3>
              <ul>
                <li>Download the Dilivva sender app</li>
                <li>Sign up for dilivva sender account</li>
                <li>Sign-in with your dilivva sender credentials</li>
                <li>upload necessary documentation and await verification</li>
                <li>You can now upload your goods and pricing details</li>
              </ul>
              <a
                href={
                  navigator.userAgent.toLowerCase().indexOf("android") > -1
                    ? "https://play.google.com/store/apps/details?id=com.nqb8.dilivvasender"
                    : navigator.userAgent.toLowerCase().indexOf("iphone") > -1
                    ? "https://apps.apple.com/us/app/dilivva-sender/id1662208767"
                    : "https://play.google.com/store/apps/details?id=com.nqb8.dilivvasender"
                }
                target="_blank"
                rel="noreferrer"
                className="btn-link shadow-none"
              >
                Download app
              </a>
            </div>
            <div className="col-md-5">
              <img src={store3} className="img-fluid px-5" alt="" />
            </div>
          </div>
        </div>
        <DownloadApp />
      </section>
    </>
  );
};

export default Store;
