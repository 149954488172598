import DownloadApp from "../DownloadApp";
import { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";

import track from "../assets/images/track-location.png";

const center = {
  lat: 6.4307739,
  lng: 3.4301063,
};

const Track = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [packageId, setPackageId] = useState("");
  const [errors, setErrors] = useState("");
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [item, setItem] = useState({});

  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [directions, setDirections] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [details, setDetails] = useState(false);

  if (!isLoaded) {
    return "loading....";
  }

  console.log(map);

  // Track Package
  const trackPackage = async (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(
      `${baseURL}/api/v1/orders/${packageId}`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        setDisbaleButton(false);
        setButtonLoad(false);
        console.log(response, "response here");
        console.log(response.status);
        if (response.status === "error") {
          setErrors(response.message);
        } else {
          setItem(response.data);
          setErrors("");
          setDetails(true);
          let pickup = response.data.pickup_address;
          let destination = response.data.transit_location.address;
          calculateRoute(pickup, destination);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  // Calculate route
  async function calculateRoute(pickup, destination) {
    if (item?.pickup_address === "" || item?.destination_address === "") {
      return;
    }
    console.log(pickup);
    console.log(item?.destination_address);
    console.log(item?.transit_location?.address, destination);
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: `${pickup}`,
      destination: `${destination}`,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirections(results);
    console.log(results);
    console.log(results.routes[0].legs[0].start_location.lat());
    console.log(results.routes[0].legs[0].start_location.lng());
    const lat = results.routes[0].legs[0].start_location.lat();
    const lng = results.routes[0].legs[0].start_location.lng();
    setCoordinates(`${lat}, ${lng}`);
    console.log(`${lat}, ${lng}`);
    console.log(coordinates, "coordinates");
  }

  return (
    <>
      <div className="container">
        {!details ? (
          <div className="row">
            <h2 className="mt-5">
              <span className="time">Track</span> your package in
              <span className="time mx-2">real-time</span>
            </h2>
            <div className="col-md-6 my-5">
              <div className="card p-4 shadow border-0">
                <div className="card-body">
                  <form onSubmit={trackPackage}>
                    <div className="mt-5 mb-3">
                      <label htmlFor="pickup">Order Reference</label>
                      <input
                        type="text"
                        className="form-control shadow-none py-3"
                        value={packageId}
                        onChange={(e) => setPackageId(e.target.value)}
                        placeholder="Enter order reference to track"
                        required
                      />
                      <small className="text-danger">{errors}</small>
                    </div>

                    <button type="submit" disabled={disableButton}>
                      {buttonLoad ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border text-white"
                            role="status"
                            style={{
                              width: "25px",
                              height: "25px",
                              fontSize: "10px",
                            }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        "Track Your Package"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 my-5 d-none d-md-block">
              <img src={track} alt="track" className="img-fluid" />
            </div>
          </div>
        ) : (
          <div>
            {item?.transit_location !== null ? (
              <div className="col-md-12 my-5 bg-white p-4 my-1 rounded">
                <div className="map" style={{ height: "100vh", width: "100%" }}>
                  <GoogleMap
                    center={center}
                    zoom={15}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    onLoad={(map) => setMap(map)}
                  >
                    <Marker position={center} />
                    {directions && (
                      <DirectionsRenderer directions={directions} />
                    )}
                  </GoogleMap>
                </div>
              </div>
            ) : (
              <p className="content">
                Your oder status is{" "}
                <span
                  style={{
                    color:
                      item?.status === "completed"
                        ? "#29CC97"
                        : item?.status === "accepted"
                        ? "#29CC79"
                        : item?.status === "cancelled"
                        ? "#DC1515"
                        : "#FEC400",
                  }}
                >
                  {item?.status}
                </span>
              </p>
            )}
            <div className="row my-5">
              <h4>Order Details</h4>
              <div className="card shadow border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <small className="text-success">Pickup Address</small>
                      <p>{item?.pickup_address}</p>
                    </div>
                    <div className="col-md-6">
                      <small className="text-success">
                        Destination Address
                      </small>
                      <p>{item?.destination_address}</p>
                    </div>
                    <div className="col-md-6">
                      <small className="text-success">Price</small>
                      <p>₦ {item?.price}</p>
                    </div>
                    <div className="col-md-6">
                      <small className="text-success">Status</small>
                      <p
                        style={{
                          color:
                            item?.status === "completed"
                              ? "#29CC97"
                              : item?.status === "accepted"
                              ? "#29CC79"
                              : item?.status === "cancelled"
                              ? "#DC1515"
                              : "#FEC400",
                        }}
                      >
                        {item?.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <h4>Package Details</h4>
              <div className="card shadow border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <small className="text-success">label</small>
                      <p>{item?.package?.label}</p>
                    </div>
                    <div className="col-md-3">
                      <small className="text-success">Size</small>
                      <p>{item?.package?.size}</p>
                    </div>
                    <div className="col-md-3">
                      <small className="text-success">Reference</small>
                      <p>{item?.package?.reference}</p>
                    </div>
                    <div className="col-md-3">
                      <small className="text-success">Fragile</small>
                      <p>{item?.package?.is_fragile === 0 ? "No" : "Yes"}</p>
                    </div>
                    <div className="col-md-4">
                      <img
                        src={item?.package?.images[0]}
                        width={100}
                        className="img-fluid"
                        alt={item?.package?.label}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <h4>Timeline</h4>
              <div className="card shadow border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <small className="text-success">Created at</small>
                      <p>{item?.timeline?.created_at}</p>
                    </div>
                    {item?.timeline?.confirmed_at && (
                      <div className="col-md-3">
                        <small className="text-success">Confirmed at</small>
                        <p>{item?.timeline?.confirmed_at}</p>
                      </div>
                    )}
                    {item?.timeline?.accepted_at && (
                      <div className="col-md-3">
                        <small className="text-success">Accepted at</small>
                        <p>{item?.timeline?.accepted_at}</p>
                      </div>
                    )}
                    {item?.timeline?.transit_started_at && (
                      <div className="col-md-3">
                        <small className="text-success">
                          Transit started at
                        </small>
                        <p>{item?.timeline?.transit_started_at}</p>
                      </div>
                    )}
                    {item?.timeline?.destination_reached_at && (
                      <div className="col-md-3">
                        <small className="text-success">
                          Destination reached at
                        </small>
                        <p>{item?.timeline?.destination_reached_at}</p>
                      </div>
                    )}
                    {item?.timeline?.released_at && (
                      <div className="col-md-3">
                        <small className="text-success">Released at</small>
                        <p>{item?.timeline?.released_at}</p>
                      </div>
                    )}
                    
                    {item?.timeline?.completed_at && (
                      <div className="col-md-3">
                        <small className="text-success">Completed at</small>
                        <p>{item?.timeline?.completed_at}</p>
                      </div>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <DownloadApp></DownloadApp>
    </>
  );
};

export default Track;
