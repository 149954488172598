import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

const CareerDetails = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState("");
  const [coverLetter, setCoverLetter] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // captcha
  function onChange(value) {
    console.log("Captcha value:", value);
    setDisable(false);
  }

  // Submit application
  const submitApplication = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDisable(true);
    const data = new FormData();
    data.append("upload_preset", "applicataion form");
    data.append("full_name", name);
    data.append("email", email);
    data.append("resume", resume);
    data.append("cover_letter", coverLetter);
    try {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
        body: data,
      };
      const url = process.env.REACT_APP_BASEURL;
      const response = await fetch(`${url}/api/v1/career-application`, options);
      const responseData = await response.json();
      // Do something with the response data
      setLoading(false);
      toast.success(responseData.message);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    } catch (error) {
      // Handle any errors
      console.log(error);
      setDisable(false);
      toast.error(error.message);
    }
  };
  return (
    <div className="container">
      <ToastContainer />
      <div className="row">
        <div className="col-md-7">
          <h4 className="my-5">About the job</h4>
          <p className="content">
            Job Title: Sales Lead We are a rapidly growing start-up company that
            connects businesses to couriers for same-day delivery services in
            Nigeria. We are seeking a dynamic and results-driven Sales Lead to
            join our team and drive sales growth.
          </p>
          <h5 className="my-5 fw-bold">Responsibilites</h5>
          <ul>
            <li>
              Develop and execute a sales strategy to acquire new business
              clients and drive revenue growth.
            </li>
            <li>
              Establish and maintain relationships with existing clients to
              drive customer retention and repeat business.
            </li>
            <li>
              Identify new market opportunities and collaborate with the
              marketing team to develop targeted campaigns.
            </li>
            <li>
              Collaborate with operations and customer service teams to ensure
              customer satisfaction and timely delivery.
            </li>
            <li>
              Provide regular reports on sales performance and customer feedback
              to the leadership team.
            </li>
          </ul>
          <h5 className="my-4 fw-bold">
            Requirements and Qualification for the role
          </h5>
          <ul>
            <li>
              Bachelor's degree in Business Administration, Marketing, or a
              related field.
            </li>
            <li>
              3+ years of experience in sales, preferably in the logistics or
              transportation industry.
            </li>
            <li>Proven track record of meeting or exceeding sales targets.</li>
            <li>Excellent communication and interpersonal skills.</li>
            <li>
              Ability to work independently and as part of a team in a
              fast-paced, dynamic environment.
            </li>
            <li>Strong problem-solving and analytical skills.</li>
          </ul>
          <p className="content">
            We offer a competitive salary, performance-based bonuses, and equity
            ownership opportunities. This is a unique opportunity to join a
            start-up team and make a significant impact on the growth and
            success of the company. If you are passionate about sales and
            logistics and want to be a part of a fast-growing team, we encourage
            you to apply.
          </p>
          <p className="content">
            "Join our team as Sales Lead for Nigeria's fastest-growing same-day
            delivery platform! Drive revenue growth by acquiring new business
            clients and building strong relationships with existing ones. Be a
            part of a dynamic and rewarding startup culture."
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 bg-white p-4 my-5 rounded">
          <form onSubmit={submitApplication}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name">Email</label>
                  <input
                    type="email"
                    className="form-control shadow-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="name">Upload CV</label>
                  <input
                    type="file"
                    name="file"
                    className="form-control shadow-none"
                    onChange={(e) => {
                      setResume(e.target.files[0]);
                    }}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Cover Letter</label>
                  <textarea
                    type="email"
                    className="form-control shadow-none"
                    rows={5}
                    value={coverLetter}
                    onChange={(e) => setCoverLetter(e.target.value)}
                    required
                  />
                </div>
              </div>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={onChange}
              />
              <div className="d-flex justify-content-end">
                <button type="submit" disabled={disable}>
                  Apply{" "}
                  {loading && (
                    <div
                      className="spinner-border text-white mx-2"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        fontSize: "10px",
                      }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerDetails;
