import { useEffect, useState } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
import hero from "../assets/images/dilivva.png";
import cost from "../assets/images/cost.svg";
import track from "../assets/images/track.svg";
import store from "../assets/images/store.svg";
import courier from "../assets/images/courier.svg";
import insured from "../assets/images/insured.svg";
import send from "../assets/images/delivery.svg";
import logo from "../assets/images/dilivva-icon.jpg";
import DownloadApp from "../DownloadApp";

const Home = ({ item }) => {
  console.log(item, "data passed from uitem");
  const [pickupAddress, setPickupAddress] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [pickupState, setPikcupState] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [available, setAvailable] = useState();
  const [destinationAvailable, setDestinationAvailable] = useState();
  const [showState, setShowState] = useState(false);

  const [pickup] = useState({
    lat: null,
    lng: null,
    state: null,
  });
  const [destination] = useState({
    lat: null,
    lng: null,
    state: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    console.log(ll.lat, ll.lng);
    console.log(
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ]
    );
    setPickupAddress(value);
    setPikcupState(
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ]
    );

    const pickupLat = ll.lat;
    const pickupLng = ll.lng;
    const pickupState =
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ];

    pickup.lat = pickupLat;
    pickup.lng = pickupLng;
    pickup.state = pickupState;

    checkPickupLocation();
  };

  const handleDestinationSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    console.log(ll.lat, ll.lng);
    console.log(
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ]
    );
    setDestinationAddress(value);
    setDestinationState(
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ]
    );

    const destinationLat = ll.lat;
    const destinationLng = ll.lng;
    const destinationState =
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ];

    destination.lat = destinationLat;
    destination.lng = destinationLng;
    destination.state = destinationState;

    checkDestinationLocation();
  };

  const checkPickupLocation = () => {
    setAvailable("");
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        state: pickup.state,
        coordinates: `${pickup.lat}, ${pickup.lng}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.status !== "success") {
          console.log(response.status);
          setAvailable(response.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const checkDestinationLocation = () => {
    setDestinationAvailable("");
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        state: destination.state,
        coordinates: `${destination.lat}, ${destination.lng}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.status !== "success") {
          console.log(response.status);
          setDestinationAvailable(response.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const [sizes, setSizes] = useState(null);
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowState(false);
  }, []);

  // Fetch package size
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/sizes`, options)
      .then((data) => data.json())
      .then((response) => {
        setSizes(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const [productSize, setProductSize] = useState("");
  const [price, setPrice] = useState(null);

  // Calculate Pricing
  const calculatePrice = async () => {
    setShowPrice(false);
    setDisbaleButton(true);
    setButtonLoad(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        size_id: productSize,
        pickup_coordinates: `${pickup.lat}, ${pickup.lng}`,
        destination_coordinates: `${destination.lat}, ${destination.lng}`,
        pickup_state: pickup.state,
        destination_state: destination.state,
        is_fragile: 0,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(`${baseURL}/api/v1/pricing`, options)
      .then((data) => data.json())
      .then((response) => {
        setDisbaleButton(false);
        setButtonLoad(false);
        setPrice(response.data.price);
        setShowPrice(true);
        // setHidePrice(false);
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  return (
    <>
      <section className="py-5" id="hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6 py-5">
              <h2 className="mt-5">
                Dilivva your packages <br />
                <span className="time">on time</span>
              </h2>
              <p className="">
                Sameday & affordable pickup & delivery service in Lagos, Ibadan,
                Abuja - Nigeria. Our trusted logistics partners; dispatch riders
                & drivers across Lagos, Ibadan, Abuja & other cities in Nigeria
                ensure you get fast & secure delivery services.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={hero}
                className="img-fluid"
                alt="delivery services in surulere"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-6" id="estimate">
        <div className="container">
          <div className="row reverse">
            <div className="col-md-6">
              <img
                src={cost}
                className="img-fluid p-5"
                alt="cheapest logistics company in nigeria"
              />
            </div>
            <div className="col-md-6 mt-5">
              <h3 className="mt-5">
                Know how much it cost <span className="time">upfront</span>
              </h3>
              <p className="content">
                Dilivva is the cheapest logistics company in Nigeria. With just a
                few clicks you will see the price, No Hidden cost Ever.
              </p>
              {/* Button trigger modal */}
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Get a quick estimate{" "}
                {showState && (
                  <small>
                    {pickupState}
                    {destinationState}
                  </small>
                )}
              </button>

              {/* Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Get an estimate for your delivery
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="my-4">
                          <label htmlFor="pickup">Pickup Address</label>
                          <PlacesAutocomplete
                            value={pickupAddress}
                            onChange={setPickupAddress}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter Pickup Address",
                                    className: "form-control shadow-none",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.index}
                                      >
                                        <span onSelect={checkPickupLocation}>
                                          {suggestion.description}
                                        </span>
                                        <hr />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <small className="text-danger">{available}</small>
                        </div>
                        <div className="my-4">
                          <label htmlFor="destination">
                            Destination Address
                          </label>
                          <PlacesAutocomplete
                            value={destinationAddress}
                            onChange={setDestinationAddress}
                            onSelect={handleDestinationSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter Destination Address",
                                    className: "form-control shadow-none",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.index}
                                      >
                                        <span
                                          onSelect={checkDestinationLocation}
                                        >
                                          {suggestion.description}
                                        </span>
                                        <hr />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <small className="text-danger">
                            {destinationAvailable}
                          </small>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="">size</label>
                          <select
                            className="form-select shadow-none"
                            value={productSize}
                            onChange={(e) => setProductSize(e.target.value)}
                          >
                            <option value="small">--select size--</option>
                            {sizes?.map((size) => {
                              const { id, uuid, name, description } = size;
                              return (
                                <option className="" key={id} value={uuid}>
                                  {name} ({description})
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <button
                          type="submit"
                          onClick={calculatePrice}
                          disabled={disableButton}
                        >
                          {buttonLoad ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border text-white"
                                role="status"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  fontSize: "10px",
                                }}
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          ) : (
                            "Check Price"
                          )}
                        </button>
                      </form>
                      <div className="text-center mt-4">
                        {showPrice && (
                          <h2 className="text-success">₦{price}</h2>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="senders">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3>
                <span className="time">Track</span> your package
                <span className="time">real-time</span>
              </h3>
              <p className="content mb-3">
                Put your mind at rest: you can see the exact location of your
                package, your recipient can see it too. With live trackers, you
                can know when your package are close by, plus you get notified
                when the package is delivered
              </p>

              <Link className="btn-link text-white" to="/track">
                Track Your Package
              </Link>
            </div>
            <div className="col-md-5 mb-5">
              <img
                src={track}
                className="img-fluid"
                alt="delivery services in abuja"
              />
            </div>
          </div>
          <div className="row reverse">
            <div className="col-md-5 mb-5">
              <img
                src={send}
                className="img-fluid p-5"
                alt="dispatch rider lagos"
              />
            </div>
            <div className="col-md-7">
              <h3>
                Send any
                <span className="time">shape</span> or
                <span className="time">size</span>
              </h3>
              <p className="content">
                It doesn’t matter the shape or size, We love shapes and sizes-
                No pun intended, we’ll take it there safely
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3>
                Dilivva <span className="time"> Store</span>
              </h3>

              <p className="content">
                Good news for business that sell. You can create a quick store
                where your customers can pay for their goods and delivery charge
                at once. Once you signup, you enjoy the ride
              </p>
              <Link className="btn-link" to="/store" role="button">
                More details
              </Link>
            </div>
            <div className="col-md-5">
              <img
                src={store}
                className="img-fluid p-5"
                alt="riders in lagos"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row reverse">
            <div className="col-md-5">
              <img
                src={courier}
                className="img-fluid"
                alt="delivery from lagos to ibadan"
              />
            </div>
            <div className="col-md-7">
              <h3>
                Courier <span className="time"> Company</span>
              </h3>

              <p className="content">
                Dilivva offers a platform for courier companies to earn more
                income. With dilivva's courier company service, you can easily
                view available packages in various areas, and you can have a
                dispatch sent to the pick up location and deliver the package,
                then get paid. Easy money
              </p>
              <Link className="btn-link" to="/courier" role="button">
                Register Company
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3>
                Your <span className="time">packages</span>are{" "}
                <span className="time">insured</span>
              </h3>
              <p className="content">
                If your item ever gets damaged or missing, it’s insured. Our
                delivery partners are well trained in package handling and
                safety to ensure safe and secure delivery. However, if damages
                or loss happens, we’ll take care of it.
              </p>
            </div>
            <div className="col-md-5">
              <img
                src={insured}
                className="img-fluid p-5"
                alt="dispatch riders in lagos"
              />
            </div>
          </div>
        </div>
      </section>

      <DownloadApp />

      <section className="py-5">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div
              className="col-md-10 py-5 rounded"
              style={{ backgroundColor: "#0c1d37" }}
            >
              <h3 className="text-white text-center">
                What our customers are saying{" "}
              </h3>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="true"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                  ></button>
                </div>
                <div className="carousel-inner px-5">
                  <div className="carousel-item active">
                    <p className="content text-white p-5">
                      These Dilivva people picked up from my shop in Lagos and
                      delivered to my clients in Ibadan within 4 hours… I
                      couldn’t believe it
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className="content text-white p-5">
                      I sent my music instruments including drum set, from
                      Abeokuta to Lekki within the same day; no dulling at all
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className="content text-white p-5">
                      What I loved most was the thrill of seeing where my
                      package was at every point in time… Thank you Dilivva
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className="content text-white p-5">
                      I sent medication to my daughter in Babcock and she got it
                      for use the same evening at her dorm… I can’t thank you
                      enough Dilivva
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className="content text-white p-5">
                      I sleep well now knowing that they will pick my customers’
                      multiple orders from my home or shop, even on Sundays and
                      still deliver it same day
                    </p>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FloatingWhatsApp
        phoneNumber="+2349158867851"
        accountName="Dilivva"
        avatar={logo}
      />
    </>
  );
};

export default Home;
