const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="py-5">Privacy Policy</h2>
          <p>
            This Privacy Policy describes how Dilivva Africa Digital Tech
            Limited ("Dilivva", "we", "us", or "our") collects, uses, and
            protects the personal information of users who use the Dilivva
            Sender and Dilivva Partner applications ("Applications") to send
            packages or become partners in moving packages.
          </p>
          <h5>Collection of Personal Information</h5>
          <p>
            When you use the Dilivva Sender application to send packages, we may
            collect personal information such as your name, email, phone number,
            package address, alternate address, destination address, package
            name, package content, and package content image. We collect this
            information to facilitate successful delivery of your package
          </p>
          <p>
            When you use the Dilivva Partner application to become a partner, we
            may collect personal information such as your name, email, phone
            number, current location, psychometric test, driver's license,
            vehicle documents, passport photograph, and other relevant
            information. We collect this information to ensure that our partners
            are qualified and capable of delivering packages.
          </p>
          <h5>Integration of Facebook and META SDK</h5>
          <p>
            Dilivva Sender app integrates the Facebook Software Development Kit (SDK) to track app installs, app downloads, download referal source or origin, and other in-app events. This integration allows us to improve the efficiency of our app, scale our user base and enhance user experience.
          </p>
          <h5>Use of Personal Information</h5>
          <p>
            We use the personal information collected through the Applications
            to facilitate successful package delivery and to improve the quality
            of our services. We may also use this information to communicate
            with you about your package delivery or to provide you with
            information about other Dilivva services.
          </p>
          <p>
            We will not sell, rent, or otherwise disclose your personal
            information to third parties for commercial purposes, except as
            required by law or as necessary to provide our services. We may,
            however, share your information with our service providers who help
            us to operate the Applications and to provide our services.
          </p>
          <p>
            We track personal information with the FaceBook SDK such as events on the app, installs, app downloads, download referal source or origin. We also track in app events such as when an order was created, if an order has been created, etc. These data would be stored on FaceBook's Server, where FaceBook's <a href="https://m.facebook.com/privacy/policy/version/20220104/">Privacy Policy</a> would apply to them.
          </p>
          <p>
            By accepting Dilivva Africa Digital Tech Limited's Privacy Policy, you acknowledge and agree to be bound by the Facebook Privacy Policy, the Facebook Platform, and the General Data Protection Regulation (GDPR). This means that you consent to the collection, use, and sharing of your personal information in accordance with these policies and regulations.
          </p>
          <p>
            Additionally, you understand that Dilivva may integrate the Facebook Software Development Kit (SDK) into its applications, and you consent to the tracking of app installs, app downloads, and referrals of downloads through the SDK as described in the Facebook Platform and GDPR compliance documentation.
          </p>
          <p>
            By giving Dilivva your consent, you understand that your personal information may be shared with Facebook as necessary for the functioning and improvement of our services in accordance with the Facebook Privacy Policy and GDPR requirements.
          </p>
          <p>
            It is important to carefully review and understand the Facebook Privacy Policy, the Facebook Platform, and the GDPR compliance documentation before accepting Dilivva's Privacy Policy. If you do not agree with any part of these policies and regulations, you should refrain from using Dilivva's services.
          </p>
          <h5>Protection of Personal Information</h5>
          <p>
            We take the security of your personal information seriously and have
            implemented appropriate physical, technical, and administrative
            measures to protect it from loss, theft, unauthorized access,
            disclosure, alteration, and destruction. We also require our service
            providers to implement similar measures to protect your personal
            information.
          </p>
          <h5>Retention of Personal Information</h5>
          <p>
            We will retain your personal information for as long as necessary to
            fulfill the purposes for which it was collected, to comply with
            applicable laws and regulations, and to protect our legal rights.
          </p>
          <h5>Your Rights</h5>
          <p>
            You have the right to access, correct, or delete your personal
            information at any time. You may also object to or restrict the
            processing of your personal information or request that we transfer
            it to another service provider. To exercise any of these rights,
            please contact us at support@dilivva.com.
          </p>
          <h5>Changes to this Privacy Policy</h5>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws. We will post any
            updates on the Dilivva website and will notify you of any material
            changes to this Privacy Policy.
          </p>
          <h5>Contact Us</h5>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at support@dilivva.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
