import React, { useEffect } from "react";
import hero from "../assets/images/hero-phone.png";

const Download = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const isIOS = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isAndroid = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  };

  const redirectUrl = () => {
    if (isIOS()) {
      window.location.href = 'https://apps.apple.com/ng/app/dilivva-sender/id1662208767';
    } else if (isAndroid()) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.nqb8.dilivvasender';
    } else {
      window.location.href = 'https://dilivva.com';
    }
  };

  return (
    <>
      <section className="py-5" id="hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6 py-5">
              <h2 className="mt-5">
                You would be redirected to the 
                <span className="time">Dilivva App Store</span>
              </h2>
            </div>
            <div className="col-md-5">
              <img src={hero} className="img-fluid rounded" alt="partner" />
            </div>
          </div>
        </div> {redirectUrl()}
      </section>
       
    </>
  );
};

export default Download;
